<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      graduation: {
        id: null,
        name: '-',
        image: null
      },

      statData: null,
      wallets: null,

      cart: {
        items: {
          loading: true,
          total: 0,
          list: null
        },
        points: {
          unilevel: 0,
          graduation: 0,
        },
        subtotal: '0.00',
        sale: '0.00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: '0.00'
      },

      referral: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username,
    };
  },
  methods: {
    getGraduation() {
      api
        .get('report/graduation')
        .then(response => {
          if (response.data.status == 'success') {
            this.graduation.id = response.data.current.id
            this.graduation.name = response.data.current.name
            this.graduation.image = response.data.current.image
          }
        })
    },
    getTotalPrediction() {
      api
        .get('report/bonus/prediction')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Total de Bônus",
          value: this.total.bonus
        },
        {
          title: "Saldo Disponível",
          value: this.total.balance
        }
      ]
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get('store/cart')
          .then(response => {
            if (response.data.status == 'success') {
              this.setStorage(response.data)
            }
          })
          .catch(error => {
            this.cart.errored = error
          })
      } else {
        this.cart = JSON.parse(localStorage.cart)
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total
      this.cart.items.list = data.items.list
      this.cart.subtotal = data.subtotal
      this.cart.sale = data.sale
      this.cart.shipping = data.shipping
      this.cart.total = data.total

      localStorage.cart = JSON.stringify(this.cart)
    },
  },
  mounted() {
    this.getTotalPrediction()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getCart()
  }
};
</script>

<template>
  <Layout>
    <router-link v-if="cart.items.total > 0" to="/store/cart" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-warning rounded p-0">
          <div class="d-flex align-items-center">
            <img class="rounded-circle header-profile-user m-2 mx-2" :src="cart.items.list[Object.keys(cart.items.list)[0]].image" width="30px" height="30px">
            <div class="py-3">Você deixou alguns produtos em seu carrinho, clique aqui para finalizar o seu pedido!</div>
          </div>
        </div>
      </div>
    </router-link>

    <Banners></Banners>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          Link de indicação
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="referral">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral
            " v-on:click="$toast.success('Copiado!');">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-6 col-xl-6">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>
  </Layout>
</template>